[id*="dropdown-menu"] {
  @apply flex flex-col py-0 mx-3.5 md:mx-0 border-t-0 border-gray-3 cursor-pointer;

  &[aria-hidden="true"] {
    @apply hidden md:flex;
  }
}

[data-target*="dropdown"] {
  @apply w-full md:hidden flex items-center justify-between gap-2 p-2 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden;

  & > span {
    @apply hidden font-semibold text-secondary;

    &:only-of-type,
    &.is-active {
      @apply flex items-center gap-2 [&_svg]:fill-current;
    }
  }

  > svg {
    @apply w-8 h-8 flex-none text-secondary fill-current;
  }

  &[aria-expanded="false"] > svg:last-of-type,
  &[aria-expanded="true"] > svg:first-of-type {
    @apply hidden;
  }

  &[aria-expanded="true"] > svg:last-of-type,
  &[aria-expanded="false"] > svg:first-of-type {
    @apply block;
  }
}

.dropdown {
  @apply absolute border-2 border-gray-3 rounded min-w-max p-4 drop-shadow-md text-left z-10;

  /*
    NOTE: the calculated value is the sum of the arrow offset position plus the half of the arrow size:
    - offset position: 20%
    - arrow size: 1.5rem
  */
  --arrow-offset: 20%;
  --arrow-size: 1.5rem;
  --arrow-visible-size: var(--arrow-size) * 0.5;

  & > * {
    @apply relative z-10 p-3.5 first:pt-1.5 last:pb-1.5;
  }

  &__item {
    @apply gap-x-2 flex items-center text-secondary;

    font-size: 14px;

    > svg {
      @apply w-4 h-4 flex-none text-gray fill-current;
    }

    &:hover {
      @apply underline [&>svg]:text-secondary;
    }
  }

  &__bottom {
    @apply top-full right-0 mt-3 translate-x-[calc(var(--arrow-offset)-var(--arrow-visible-size))] before:content-[''] before:absolute before:right-[var(--arrow-offset)] before:-top-2 before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-white before:rounded before:border-2 before:border-gray-3 after:content-[''] after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-white;
  }
}
