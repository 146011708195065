.voc-accordion {
  @apply bg-transparent;

  .accordion-title {
    @apply text-inherit border-0 rounded-none pl-4 flex items-center justify-start;

    span {
      @apply cursor-move;
    }

    &:focus {
      @apply bg-transparent;
    }
  }

  .accordion-item {
    // Override Foundation
    &:first-child > :first-child,
    &:last-child:not(.is-active) > .accordion-title {
      @apply border-0 rounded-none;
    }
  }

  .accordion-content {
    @apply border-0 mt-4;

    .tabs-title > a[aria-selected="true"],
    .tabs-content {
      @apply bg-transparent;
    }
  }
}
