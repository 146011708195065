.focus {
  $top-padding: 0.5rem;

  @apply flex flex-col min-h-screen text-gray-2 bg-gray-5;

  &__top {
    padding: $top-padding 1rem;

    @apply bg-white sticky top-0 z-10 flex;

    a {
      @apply flex items-center text-md text-secondary;
    }
  }

  &__top_warning {
    background: rgb(var(--alert-rgb));

    @apply text-white flex items-center justify-center py-2;

    svg {
      @apply text-xl mr-2 fill-white;
    }
  }

  &__brand {
    @apply flex items-center;

    img {
      max-height: 20px;
    }
  }

  &__user {
    @apply ml-auto mr-4 pr-0 flex items-center;
  }

  &__exit {
    @apply flex items-center leading-none my-[-var(--top-padding)] py-2 pl-4 pr-1 border-l border-gray-2;
  }

  &__x-icon {
    @apply text-2xl mr-2;

    a:hover & {
      @apply text-inherit;
    }
  }

  &__header {
    @apply bg-gray-4 text-white p-4;

    &-title {
      @apply mb-0;
    }
  }

  &__step {
    @apply flex flex-col flex-grow;
  }

  .focus__step.hidden {
    display: none !important;
  }

  &__steps {
    @apply uppercase text-xs tracking-wider text-gray;
  }

  &__content {
    @apply flex-grow flex;

    .row {
      @apply flex-grow;
    }
  }

  &__footer {
    @apply mt-auto p-4 bg-white;

    .row {
      @apply flex items-baseline w-full;
    }

    .button {
      @apply mb-0;
    }
  }

  &__back {
    @apply font-semibold;

    svg {
      @apply text-xs mr-1 align-middle;
    }
  }

  &__next {
    @apply ml-auto;

    svg {
      @apply text-xs ml-1 align-middle;
    }
  }
}

// Fix for external link indicator
a img + .external-link-indicator {
  @apply top-[-10px] right-[-15px];
}
